.container {
  align-self: flex-start;
  padding: 4px;
  border-radius: 2px;
  width: fit-content;
}

.containerSm {
  border-radius: 2px;
  align-self: flex-start;
  padding: 2px;
  width: fit-content;
  font-size: 10px;
}
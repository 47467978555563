.pageContentContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.topText {
  max-width: 225px;
  font-size: 12px;
  height: 50px;
}

.bottomText {
  width: 300px;
  font-size: 12px;
  height: 50px;
}

.bold {
  font-weight: 700;
}

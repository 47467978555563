.modalTitle {
  justify-content: space-between;
  font-size: 20px;
  padding: 10px;
}

.bodyWrapper {
  display: flex;
  flex-direction: column;
  width: 385px;
  text-align: center;
}

.modalBody {
  display: flex;
  justify-content: center;
}

.errorsWrapper {
  margin: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.retryButton {
  background-color: var(--sparket-gold) !important;
  height: 50px;
  border-radius: 5px;
  border: none !important;
  width: 75%;
}

.wrapper {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
    margin-left: 20px;
    margin-right: 20px;
    border: 1px solid var(--secondary-color);
    cursor: pointer;
    background-color: var(--sparket-black);
    border-radius: 10px;
}

.contestName {
    flex: .7;
    font-size: 16px;
    margin-bottom: 5px;
    color: var(--secondary-color);
}

.feeContainer {
    flex: .15;
    display: flex;
    flex-direction: column;
}

.contestContainer {
    flex: 1;
    margin-left: 10px;
    padding-top: 15px;
    padding-bottom: 10px;
}

.infoRow {
    display: flex;
    flex-direction: row;
}

.contestDetailsWrapper {
     margin-top: 10px;
     flex-wrap: nowrap;
     display: flex;
     justify-content: space-between;
     align-items: flex-end;
}

.divider {
    height: 50px;
    align-self: center;
    border: 1px solid lightgray;
}

.bottomBar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.bottomColumn {
    display: flex;
    flex-direction: column;
    width: 130px;
}
.container {
  background-color: white;
}

.rowContainer {
  font-size: 15px;
}

.badgeGridMobile {
  display: grid;
  grid-template-rows: repeat(1, 20px);
  grid-template-columns: repeat(4, 20px);
  margin-left: 5px;
}

.badgeDisplayMobile {
  height: 20px;
  width: 20px;
}

.badgeGridFull {
  display: grid;
  grid-template-columns: repeat(4, 20px);
  margin-left: 5px;
}

.badgeDisplayFull {
  height: 20px;
  width: 20px;
}

.button {
  background-color: var(--secondary-color) !important;
  border: none !important;
  color: var(--color-black) !important;
  border-radius: 20px !important;
  padding-top: 4px !important;
  padding-bottom: 5px !important;
  height: 31px !important;
  min-width: 149px !important;
}

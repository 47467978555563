.container {
  height: 75px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.logoContainer {
  height: 40px;
  margin-left: 15px;
}

.groupNameText {
  color: white;
  font-size: 25px;
}

.groupLogoContainer {
  display: inline-flex;
  align-items: center;
  gap: 1rem;
}

.clickableGroupNameText {
  cursor: pointer;

  &:hover {
    color: var(--sparket-gold);
  }
}

.profilePicContainer {
  margin-right: 4px;
  height: 40px;
  align-self: flex-start;
  margin-top: 5px;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.profilePic {
  width: 30px;
  height: 30px;
  object-fit: cover;
}

.profileRow {
  display: flex;
  flex-direction: row;
}

.badgeColumn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 40px;
  max-width: 10px;
}

.badgePic {
  width: 10px;
  height: 10px;
  object-fit: cover;
}
.rs-drawer-content {
    background-color: var(--sparket-black) !important;
    color: white;
    box-shadow: 1.4px 2.9px 2.9px hsl(0deg 0% 0% / 0.46) !important;
}

.rs-drawer-header {
    color: white;
}

.rs-drawer-header .rs-drawer-header-close {
    font-size: 1rem;
    color: rgb(87, 87, 87);
    font-weight: 400;
    position: absolute;
    right: 0 !important;
    top: 0 !important;
    left: unset !important;
}
.bankrollTableHeader {
    font-size: 18px;
}

.balanceRow {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    padding: 12px;
    font-size: 20px;
}

.pageContainer {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.contentContainer {
    flex-grow: 1;
}

.footer {
    position: sticky;
    bottom: 0;
    padding: 12px;
    display: flex;
    justify-content: space-between;
    z-index: 10;
}

.customToggle :global(.rs-toggle-presentation) {
    background-color: var(--sparket-light-gray) !important;
    color: var(--sparket-black) !important;
    font-weight: 700;
}

.customToggle :global(.rs-toggle-presentation::after) {
    background-color: var(--sparket-gold) !important;
}
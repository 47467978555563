.body {
  background-color: var(--sparket-black);
  color: white;
  height: 40vh;
  overflow: auto;
}

.groupButton {
  margin-bottom: 10px;
  cursor: pointer;

  &:hover {
    color: var(--secondary-color);
  }
}

.currentGroupIon {
  color: var(--secondary-color);
  font-size: 18px;
}

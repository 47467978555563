.body {
    display: flex;
    flex-direction: column;
    margin: 20px;
    gap: 10px;
}

.modal {
    background-color: var(--sparket-black);
}

.pageWrapper {
    border-radius: 0px;
    border: 1px solid var(--secondary-color);
    flex-direction: column;
    padding: 5px;
    cursor: inherit;
    background-color: var(--sparket-black)
}


.infoSection {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow-y: auto
}

.contestInfoSection {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 10px;
}

.contestInfoColumn {
    width: 50%;
    flex: 1;
    display: flex;
    flex-direction: column;
}

.infoText {
    font-size: 10px;
    color: gray;
}
.modalTitle {
  display: flex;
  justify-content: center;
}

.modalBody {
  border-width: 3;
  border-style: solid;
  display: flex;
  flex-direction: column;
  align-items: center;
}

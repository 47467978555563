.userButtonContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    margin-right: 5px;
    margin-top: 3px;
}

.userButtonContainer:hover {
    color: inherit;
}

.userName {
    font-size: 12px;
    font-weight: bold;
    color: white;
    text-decoration: underline;
    text-decoration-color: white;
}

.avatarLink {
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
}

.avatarContainer {
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.avatar {
    width: 35px;
    height: 35px;
    object-fit: cover;
}

.verificationIcon {
    position: absolute;
    bottom: -3px;
    right: -5px;
    border-radius: 50%;
    border: 1px solid var(--sparket-black);
}

.notificationIcon {
    position: absolute;
    top: -2px;
    right: -5px;
    border-radius: 50%;
    border: 1px solid var(--sparket-black);
}

.userBalance {
    font-size: 12px;
    color: white;
}

.userBalance:hover {
    text-decoration: none;
}

.emptySpace {
    width: 25px;
}
.navBar {
    display: flex;
    justify-content: flex-start;
    flex: 1;
    background-color: var(--sparket-black)
}

.navLink {
    color: white !important;
    cursor: pointer;
    text-decoration: none !important;
    justify-content: flex-start;
}

.navLink[aria-selected="true"] {
    color: var(--secondary-color) !important;
    border-bottom: 2px solid var(--secondary-color) !important;
    border-radius: 0px !important;
}

.navText {
    font-size: 22px;
    text-decoration: none;
}

.navText:hover,.navLink:hover {
    text-decoration: none;
}

.joinGroupButton {
    padding-top: 10px;
    padding-bottom: 10px;
    margin-right: 20px;
    margin-left: auto;
}

.inputStyles {
  width: 90% !important;
  height: 45 !important;
  margin-bottom: 10px !important;
  border: none !important;
  border-radius: unset !important;
}

.labelStyles {
  width: 90%;
  font-size: 12px;
  opacity: 0.7;
  margin-bottom: 0;
}

.profileContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 10px;
}

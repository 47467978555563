.centeredColumn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.topContainer {
  color: var(--secondary-color);
  font-size: 16px;
}

.timeText {
    color: var(--sparket-off-white);
    font-size: 12px;
}

.bottomContainer {
    color: var(--sparket-off-white);
    display: flex;
    justify-content: center;
    font-size: 12px;
    align-items: center;
}

.prizeContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.prizeContainer:hover .bottomContainer {
  color: blue;
  text-decoration: underline;
}

.statusText {
  color: gray;
  font-size: 10px;
  margin-right: 5px;
}

.statusTypeText {
  font-size: 12px;
  margin-right: 10px;
}

.statusContainer {
  align-items: baseline;
  display: flex;
}

.disclaimer {
  color: white;
  font-size: 0.6em;
  margin-bottom: 2vh;
  text-align: justify;
}

.legalLink {
  cursor: pointer;
}

.googleButton {
  margin-bottom: 2vh;
  width: 100%;
  background-color: #ffffff;
  text-align: left;
}

.googleText {
  color: #222222;
  float: right;
  margin-right: 25%;
}

.facebookButton {
  margin-bottom: 2vh;
  width: 100%;
  background-color: #4267b2;
  text-align: left;
}

.facebookText {
  color: white;
  float: right;
  margin-right: 20%;
}

.imageGrid {
    display: grid;
    gap: 1rem;
    object-fit: cover;
    aspect-ratio: 1/1;
    height: 100%;
    width: 100%;
}

.image {
    border: 1px solid white;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    object-fit: cover;
    aspect-ratio: 1/1;
}

.image:hover {
    transform: scale(1.1);
}

.dropdownMenu {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 8px 12px;
    font-size: 16px;
    color: #333;
    width: 100%;
}

.imageContainer {
    position: relative;
    width: 100%;
    height: 100%;
}

.text {
    color: white;
    text-align: center;
}

.lockOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
}

.lockIcon {
    width: 75%;
    height: 75%;
}

.toolTip {
    background: var(--sparket-light-gray);
    color: black;
}
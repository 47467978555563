.sparketScoreContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sparketScoreHeader {
  display: flex;
  align-items: center;
  position: relative;
}

.questionCircle {
  color: white;
  margin-left: 2px;
}

.scoreText {
  font-size: 30px;
}

.scoreBarContainer {
  width: 100%;
  height: 20px;
  background-color: #ddd;
  position: relative;
}

.scoreBar {
  height: 100%;
}

.scoreIndicator {
  position: absolute;
  bottom: -12px;
  color: white;
  transform: translateX(-50%);
}

.scoreRangeContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.scoreRangeText {
  color: white;
}
.container {
  position: fixed;
  bottom: 0px;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding: 10px;
}

.contents {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.getInOnAction {
  font-size: 12px;
}

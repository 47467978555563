.disclaimer {
  color: white;
  font-size: 0.6em;
  margin-bottom: 2vh;
  text-align: justify;
}

.legalLink {
  cursor: pointer;
}

.googleText {
  color: #222222;
  float: right;
  margin-right: 25%;
}

.facebookText {
  color: white;
  float: right;
  margin-right: 20%;
}

.signUpWithGoogleButton {
  margin-bottom: 2vh !important;
  width: 100% !important;
  background-color: #ffffff !important;
  text-align: left !important;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 330px;
}

.modalContent {
  min-height: 500px;
  display: flex;
  flex-direction: column;
  margin: 20px;
  justify-content: space-between;
}

.titleContent {
  justify-self: center;
}

.mfaInputContainer {
  padding: 16px;
}

.mfaInput {
  width: 2ch;
  padding: 8px;
  border-radius: 8px;
  font-size: 40px;
  text-align: center;
  margin-right: 12px;
  border: 1px solid white;
  text-transform: uppercase;
}

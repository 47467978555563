.text {
  text-align: center;
}

.successText {
  text-align: center;
  font-weight: bold;
  font-size: 25px;
  margin-top: 20px;
}

.betSummaryText {
  margin-top: 20;
}

.bold {
  font-weight: "bold";
}

.italic {
  font-style: "italic";
}

.underline {
  text-decoration: "underline";
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.successTextContainer {
  height: 20px;
}

.shareBetContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
}

.topBarContainer {
  display: flex;
  flex-direction: row;
}

.reviewBetTextContainer {
  height: 40px;
  margin-bottom: 20px;
}
.statusAndPoolLinkContainer {
  display: flex;
  margin-bottom: 20px;
  flex-direction: row;
}
.projectedPayoutContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  height: 70px;
  padding-right: 10px;
}
.winLossSection {
  text-align: center;
}

.winLossText {
  color: white;
  font-size: 30px;
  flex-direction: column;
  justify-content: center;
  display: flex;
  height: 60px;
}

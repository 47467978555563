.outerWrapper {
  display: flex;
  justify-content: center;
  width: 100%;
}
.innerWrapper {
  width: 285px;
  padding: 4px;
  padding-left: 7px;
  padding-right: 7px;
  border-radius: 5px;
}

.badgeContentWrapper {
  display: flex;
  align-items: center;
}

.infoLink {
  color: white;
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;
}

.iconSpacing {
  width: 5px;
}

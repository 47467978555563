@import url("https://fonts.googleapis.com/css2?family=Chakra+Petch:wght@300;450;600;700&family=Open+Sans&display=swap");

:root {
  scrollbar-color:gray var(--sparket-black);
}

div.fade.show {
  pointer-events: auto i !important;
  z-index: 1000;
}

div.fade {
  opacity: inherit;
  pointer-events: auto;
}

.carousel-control-next,
.carousel-control-prev {
  width: 5% !important;
}

:root {
  --sparket-gold: #ffcd00;
  --sparket-light-gold: #ffedbb;
  --sparket-light-gray: #f1f2f2;
  --sparket-off-white: #B8B8B8;
  --sparket-black: #111920;
  --sparket-green: #66c085;
  --sparket-dark-green: #00834b;
  --sparket-light-green: #d5efdd;
  --sparket-dark-red: #c00020;
  --sparket-light-red: #fcd7d3;
  --sparket-dark-orange: #cb6f00;
  --secondary-color: --sparket-gold;
  --text-color: black;

  --winner-green: #1ba628;

  --toastify-icon-color-info: var(--sparket-gold) !important;
  --toastify-icon-color-success: var(--sparket-green) !important;
  --toastify-icon-color-warning: var(--sparket-gold) !important;
  --toastify-icon-color-error: var(--sparket-dark-red) !important;

  .Toastify__toast-body a {
    color: var(--sparket-gold);
  }
}

.Toastify__close-button--light {
  color: unset;
  color: var(--sparket-gold) !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

iframe#wagerwire-iframe {
  z-index: -1;
}

.wagerwire-iframe-visible iframe#wagerwire-iframe {
  z-index: 3;
}

.nav-pills .nav-link.active {
  color: var(--sparket-black) !important;
  background-color: var(--secondary-color) !important;
}

.nav-pills .nav-link {
  color: white;
  background-color: gray !important;
}

.nav-link {
  padding: 0.4rem 0.1rem !important;
}

.btn-check:checked + .btn-outline-success {
  background-color: var(--secondary-color) !important;
  color: black !important;
}

.btn-outline-success {
  border-color: lightgray !important;
  color: lightgray !important;
}

.btn-outline-success:hover {
  background-color: unset !important;
}

#input-BetModalContent-betAmountInput:focus-visible {
  border: 3px solid var(--sparket-gold);
  outline-width: 3px;
  outline-color: var(--sparket-gold);
}

:root {
    --avatar-col-width: 0px;
    --badge-col-width: 0px;
    --image-row-height: 0px;
}

.modalHeader {
    background-color: black;
}

.modalTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    color: white !important;
}

.modalTitle > :first-child {
    flex-grow: 1;
    text-align: center;
}

.modalBody {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 1rem;
    background-color: black;
}

.layout {
    display: grid;
    grid-template-areas:
        "profileHeader selectionHeader"
        "profileSection selectionSection"
        "achievementHeader x"
        "achievementSection buttonSection";
    grid-template-columns: var(--avatar-col-width) var(--badge-col-width);
    grid-template-rows: auto var(--image-row-height) auto auto;
    gap: 1rem;
}

.profileSection, .selectionSection, .achievementSection, .buttonSection {
    height: 100%;
    width: 100%;
}

.profileHeader {
    grid-area: profileHeader;
}

.selectionHeader {
    grid-area: selectionHeader;
}

.profileSection {
    grid-area: profileSection;
    aspect-ratio: 1;
}

.selectionSection {
    grid-area: selectionSection;
}

.achievementHeader {
    grid-area: achievementHeader;
}

.achievementSection {
    grid-area: achievementSection;
    height: 100%;
    width: 100%;
}

.buttonSection {
    display: flex;
    justify-content: center;
    grid-area: buttonSection;
    height: 100%;
    width: 100%;
}

.image {
    border: 1px solid white;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    object-fit: cover;
    aspect-ratio: 1/1;
}


.badge {
    width: 100%;
    height: 100%;
    object-fit: cover;
    aspect-ratio: 1/1;
}

.plusContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}

.plus {
    width: 75%;
    height: 75%;
    object-fit: cover;
    aspect-ratio: 1/1;
}

.badgesDisplay {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    align-content: center;
    max-width: 100%;
    height: auto;
    object-fit: contain;
    gap: 1rem;
}

.text {
    color: white;
    text-align: center;
}
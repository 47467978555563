.displayGrid {
    display: grid;
    grid-template-areas:
        "avatar"
        "badges";
    width: 300px;
    justify-items: center;
    align-items: center;
    gap: 5px;
}

.badges {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    max-height: 63px;
    width: 63px;
    gap: 1rem;
}

.avatar {
    width: 300px;
    height: 300px;
}

.image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.badge {
    width: 63px;
    height: 63px;
}


.itemContainer {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 20px;
  margin-right: 20px;
  background-color: white;
  border: none;
  border-radius: 5px;
  box-shadow: 1.4px 2.9px 2.9px hsl(0deg 0% 0% / 0.46);
  cursor: pointer;
  justify-content: space-between;
}

.activeBetsText {
  color: gray;
  font-size: 15px;
  font-weight: 600;
}

.inline {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.itemLeftColumnContainer {
  display: flex;
  flex-direction: column;

  margin-left: 10px;
  padding-top: 5px;
  padding-bottom: 5px;

  word-break: break-word;
}
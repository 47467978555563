.wrapper {
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 20px;
  background-color: var(--sparket-black);
  cursor: pointer;
  border-radius: 10px;
  transition: transform .2s; /* Animation */
}

.wrapper:hover {
  transform: scale(1.01); 
  box-shadow: 0px 0px 8px #f2f2f5; // highlights the card a bit on hover
}
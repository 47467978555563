.pulsate {
    animation: pulse 1s infinite;
    transform-origin: center center; /* Scale from the center */
}

.bordered {
    border-radius: 50%;
    border: 1px solid var(--sparket-black);
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.2);
    }
    100% {
        transform: scale(1);
    }
}

.wrapper {
  display: flex;
  justify-content: center;
  background-color: black;
}

.image {
  height: 100px;
  object-fit: cover;
  align-self: center;
}
